import type { FormCoworkerGetResponse } from './types'
import { useQuery } from 'react-query'
import { API_URLS } from '../../config/constants'
import axiosClient from '../../config/axiosClient'

export const getCoWorkerForm = async () => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const { data } = await axiosClient.get<FormCoworkerGetResponse>(API_URLS.FORMS.COWORKER.GET)

  return data
}

const useCoWorkerFormQuery = () => {
  return useQuery<FormCoworkerGetResponse | undefined>('coworkerForm', getCoWorkerForm)
}

export default useCoWorkerFormQuery
