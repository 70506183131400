import { useMutation } from 'react-query'
import type {FormChangeReviewerPostPayload, FormChangeReviewerPostResponse} from "./types";
import {API_URLS} from "../../config/constants";
import axiosClient from "../../config/axiosClient";


export const postChangeReviewerForm = async (formData: FormChangeReviewerPostPayload) => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }
  const { data } = await axiosClient.post<FormChangeReviewerPostResponse>(API_URLS.FORMS.CHANGE_REVIEWER.POST, formData)
  return data
}

const useChangeReviewerFormMutation = () => {
  return useMutation(postChangeReviewerForm);
}

export default useChangeReviewerFormMutation;
