import {useMutation} from "react-query";
import {renderToast} from "../../components/SNToast";
import {getFriendlyErrorMessage} from "../../utils/errorCodeMessages";
import type { PostHawkCacheResponse } from "./types";
import {API_URLS} from "../../config/constants";
import axiosClient from "../../config/axiosClient";


export const postHawkCache = async (xkeys: string[]) => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url');
  }
  const requestBody = {xkeys};
  const { data } = await axiosClient.post<PostHawkCacheResponse>(API_URLS.FORMS.HAWK.POST, requestBody);
  return data;
}

const usePostHawkCacheMutation = () => {

  return useMutation({
    mutationFn: (xkeys: string[]) => {
      return postHawkCache(xkeys);
    },
    onError: (err: any) => {
      renderToast({ type: 'error', title: getFriendlyErrorMessage(err?.response?.data?.errorCode), err });
    },
    onSuccess: (m) => {
      renderToast({ type: 'success', title: JSON.stringify(m?.message) } );
    },
  });
}

export default usePostHawkCacheMutation
