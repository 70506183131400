import type {FormGetAllReviewersGetResponse} from './types'
import { useQuery } from 'react-query'
import { API_URLS } from '../../config/constants'
import axiosClient from '../../config/axiosClient'

export const getAllReviewers = async () => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const { data } = await axiosClient.get<FormGetAllReviewersGetResponse>(API_URLS.FORMS.CHANGE_REVIEWER.GET_ALL_REVIEWERS)

  return data
}

const useGetAllReviewersQuery = () => {
  return useQuery<FormGetAllReviewersGetResponse | undefined>('getAllReviewersForm', getAllReviewers)
}

export default useGetAllReviewersQuery;
