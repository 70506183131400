import React, {useState} from 'react';
import Icon from "./ui/Icon";
import toast from "react-hot-toast";

type SNToastProps = {
  type: 'success' | 'error' | 'warning';
  title?: string;
  err?: any;
  duration?: number;
}

export const renderToast = ({ ...props }: SNToastProps) => {
  toast.custom(() => (
    <SNToast
      type={props.type}
      title={props.title}
      err={props?.err}
    />
  ), { duration: props.duration ?? 6000 })
}

const SNToast = (props: SNToastProps) => {
  const [extraDataVisible, setExtraDataVisible] = useState(false)

  const showExtraData = () => {
    setExtraDataVisible((prev) => !prev)
    // Don't remove this log! It's purposefully there so we can investigate the error
    console.log(props.err?.response);
  }

  let colorClass;
  switch (props.type) {
    case 'success': colorClass = 'border-green-500'; break;
    case 'warning': colorClass = 'border-amber-500'; break;
    case 'error': colorClass = 'border-red-500'; break;
    default: colorClass = 'border-sky-500'; break;
  }

  return (
    <div
      className={`max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 border-l-8 ${colorClass}`}
    >
      <div className="flex-1 w-0 pr-4">
        <div className="flex items-center">
          <div className="ml-3 flex-1 p-4">
            <p className="text-base font-medium text-gray-900">
              {props.type === 'error' && (<span>Något gick fel. </span>)}
              {props.title}
            </p>
            {extraDataVisible && props.err && (
              <p className="mt-1 text-sm text-gray-500">
                <strong>ERROR CODE: </strong> {props?.err?.response?.data?.errorCode}<br />
                <strong>STATUS: </strong> {props?.err?.response?.status}<br />
                <strong>MESSAGE: </strong> {props?.err?.response?.data?.message}
              </p>
            )}
          </div>
          {props.type === 'error' && props.err && (
            <div onClick={showExtraData} className="cursor-pointer">
              <Icon icon={extraDataVisible ? 'arrowUp' : 'arrowDown'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SNToast;
