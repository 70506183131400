import NewCoWorker from '../routes/NewCoWorker'
import Report from '../routes/Report'
import Remiss from '../routes/Remiss'
import UserProfile from '../routes/UserProfile'
import NotFound from '../routes/NotFound'
import LoggedOut from '../routes/LoggedOut'
import Debug from '../routes/Debug'
import ChangeReviewer from "../routes/ChangeReviewer";
import ManageHawk from "../routes/ManageHawk";
import UploadResources from "../routes/UploadResources";

type Route = {
  path: string
  showInMainMenu: boolean
  menuText?: string
  component: React.ReactNode
}

const routes: Route[] = [
  {
    path: '/',
    showInMainMenu: true,
    menuText: 'Rapport',
    component: <Report />,
  },
  {
    path: '/remiss',
    showInMainMenu: true,
    menuText: 'Remiss',
    component: <Remiss />,
  },
  {
    path: '/ny-person',
    showInMainMenu: true,
    menuText: 'Ny person',
    component: <NewCoWorker />,
  },
  {
    path: '/din-medarbetarsida',
    showInMainMenu: true,
    menuText: 'Din medarbetarsida',
    component: <UserProfile />,
  },
  {
    path: '/faktagranskning-admin',
    showInMainMenu: true,
    menuText: 'Faktagranskning admin',
    component: <ChangeReviewer />,
  },
  {
    path: '/logged-out',
    showInMainMenu: false,
    component: <LoggedOut />,
  },
  {
    path: '/admin',
    showInMainMenu: false,
    component: <h1>Hej admin!</h1>,
  },
  {
    path: '/admin/debug',
    showInMainMenu: false,
    component: <Debug />,
  },
  {
    path: '/admin/manage-hawk',
    showInMainMenu: false,
    component: <ManageHawk />,
  },
  {
    path: '/admin/upload-resources',
    showInMainMenu: false,
    component: <UploadResources />,
  },
  {
    path: '*',
    showInMainMenu: false,
    component: <NotFound />,
  },
]

export default routes
