import Button from "../components/ui/Button";
import {confirmAlert} from 'react-confirm-alert';
import React, {useEffect, useState} from "react";
import Select from "react-select";
import useGetUploadResourcesQuery from "../hooks/api/useGetUploadResourcesQuery";
import BlurryLoading from "../components/BlurryLoading";
import type {GitBranch, GitTag} from "../hooks/api/types";
import useGetUploadResourcesMutation from "../hooks/api/useGetUploadResourcesMutation";
import {renderToast} from "../components/SNToast";
import axiosClient from "../config/axiosClient";
import {getFriendlyErrorMessage} from "../utils/errorCodeMessages";
import {API_URLS} from "../config/constants";
import ProgressBar from "../components/ui/ProgressBar";

const UploadResources = () => {
  const PUBLICATIONS = ['base', 'sn', 'ef', 'lfk']
  const [branches, setBranches] = useState<GitBranch[]>([]);
  const [tags, setTags] = useState<GitTag[]>([]);
  const [selectedBranchOrTagName, setSelectedBranchOrTagName] = useState<string>('');
  const [selectedBranchOrTag, setSelectedBranchOrTag] = useState('');
  const [uploadSharedResources, setUploadSharedResources] = useState(false);
  const [debug, setDebug] = useState(false);
  const [failure, setFailure] = useState(false);
  const [uploadPublicationSpecificResources, setUploadPublicationSpecificResources] = useState(false);
  const [selectedPublications, setSelectedPublications] = useState(PUBLICATIONS.map(item => ({
    value: item,
    label: item
  })));
  const [statusMessages, setStatusMessages] = useState([]);
  const [backgroundResultId, setBackgroundResultId] = useState('');
  const [backgroundProgressPercentage, setBackgroundProgressPercentage] = useState(0);

  const getUploadResourcesQuery = useGetUploadResourcesQuery();
  const getUploadResourcesMutation = useGetUploadResourcesMutation();

  const reset = () => {
    setSelectedBranchOrTagName('');
    setSelectedBranchOrTag('');
    setUploadSharedResources(false);
    setDebug(false);
    setFailure(false);
    setUploadPublicationSpecificResources(false);
    setSelectedPublications(PUBLICATIONS.map(item => ({value: item, label: item})));
    setStatusMessages([]);
    setBackgroundResultId('');
    setBackgroundProgressPercentage(0);
  }

  useEffect(() => {
    if (getUploadResourcesQuery.status !== 'success') {
      return;
    } else {
      if (getUploadResourcesQuery?.data?.git?.branches?.length > 0) {
        setBranches(getUploadResourcesQuery.data.git.branches);
      }
      if (getUploadResourcesQuery?.data?.git?.tags?.length > 0) {
        setTags(getUploadResourcesQuery.data.git.tags);
      }
    }
  }, [getUploadResourcesQuery.status]);

  useEffect(() => {
    if (!selectedBranchOrTagName) {
      setSelectedBranchOrTag('');
    }
  }, [selectedBranchOrTagName]);

  const triggerBackgroundCheck = async () => {
    const inter = setInterval(() => {
      axiosClient
        .get(`${API_URLS.FORMS.UPLOAD_RESOURCES.POST}/?backgroundResultId=${backgroundResultId}`)
        .then(res => {
          const percentageDone = res?.data?.backgroundRunnerPercentDone;
          const isRunning = res?.data?.backgroundRunnerIsRunning;

          if (res?.data?.messages?.length && statusMessages.length !== res?.data?.messages?.length) {
            // @ts-ignore
            setStatusMessages(res.data.messages);
          }

          setBackgroundProgressPercentage(percentageDone);

          if (!isRunning) {
            clearInterval(inter);

            if (percentageDone === 100) {
              renderToast({
                type: 'success',
                title: 'Upload completed!',
              });
            }
          }
        }).catch((e: any) => {
        clearInterval(inter);
        setFailure(true);
        setBackgroundProgressPercentage(100);
        if (e.response?.data?.messages?.length && statusMessages.length !== e.response?.data?.messages?.length) {
          // @ts-ignore
          setStatusMessages(e.response.data.messages);
        }
        renderToast({
          type: 'error',
          title: 'Upload failed',
        });
      })
    }, 1000);
  };

  useEffect(() => {
    if (backgroundResultId) {
      triggerBackgroundCheck();
    }
  }, [backgroundResultId]);

  const handleSubmit = () => {
    let extraMsg = '';
    if (location.origin.includes('https://bo.svensktnaringsliv.net') && selectedBranchOrTagName !== 'master') {
      extraMsg += ' You are in production and you have not selected the master branch';
    }
    const type = selectedBranchOrTag === 'BRANCH' ? 'BRANCH' : 'TAG';

    confirmAlert({
      overlayClassName: 'upload-resource-overlay',
      title: 'Are you sure?',
      message: `Are you sure you want to upload the selected resources?${extraMsg}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            getUploadResourcesMutation.mutate({
              uploadSharedResources,
              branchOrTag: type,
              branchOrTagName: selectedBranchOrTagName,
              publications: uploadPublicationSpecificResources ? selectedPublications.map(item => item?.label) : [],
              triggerEceError: false,
              debug,
            }, {
              onError: (err: any) => {
                setFailure(true);
                renderToast({type: 'error', title: getFriendlyErrorMessage(err?.response?.data?.errorCode), err})
              },
              onSuccess: response => {
                setBackgroundResultId(response?.backgroundResultId);
              },
            });
          }
        },
        {
          label: 'No, cancel',
          onClick: () => {
          },
        }
      ]
    });
  };

  const handleSetSelectedBranchOrTagName = (name: any, type: string) => {
    setSelectedBranchOrTagName(name);
    setSelectedBranchOrTag(type);
  };

  const handleUploadSharedResources = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadSharedResources(e.target.checked);
  };

  const handleUploadPublicationSpecificResourcesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadPublicationSpecificResources(e.target.checked);
  };

  const handleSetSelectedPublications = (val: any) => {
    setSelectedPublications(val);
  }

  const handleDebug = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDebug(e.target.checked);
  };

  const getRelativeDateDescription = (dateString: string) => {
    const time = Date.parse(dateString);
    const seconds = Math.floor((Date.now() - time) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  return (
    <div className="max-w-4xl mx-auto">
      {getUploadResourcesQuery.status === 'loading' && <BlurryLoading/>}
      <h1>Upload Resources to Cue</h1>
      {backgroundResultId && (
        <ProgressBar className="mb-4" percentage={backgroundProgressPercentage} failure={failure}/>
      )}
      {statusMessages?.length > 0 && (
        <>
          <h3 className="my-4">Status:</h3>
          <ul>
          {statusMessages.map(msg => (<li>{msg}</li>))}
          </ul>
        </>
      )}
      {!backgroundResultId && (
        <>
          {statusMessages?.length === 0 && (
            <>
              {(branches.length > 0 || tags.length > 0) && (
                <>
                  <div className="flex flex-col">
                    <h3>Choose git branch or tag</h3>
                    <div className={`flex items-center ${getUploadResourcesQuery.status === 'loading' ? '-z-10' : ''}`}>
                      {(selectedBranchOrTag === 'BRANCH' || selectedBranchOrTag === '') && (
                        <Select
                          isClearable
                          className="w-96 mr-4"
                          placeholder="Branch"
                          options={branches.map(item => ({value: item.name, label: item.name + ' (' + getRelativeDateDescription(item.date) + ')'}))}
                          onChange={(e) => handleSetSelectedBranchOrTagName(e?.value, 'BRANCH')}
                        />
                      )}
                      {(selectedBranchOrTag === 'TAG' || selectedBranchOrTag === '') && (
                        <>
                          {!selectedBranchOrTagName && <div className="mr-4">or</div>}
                          <Select
                            isClearable
                            className="w-96"
                            placeholder="Tag"
                            options={tags.map(item => ({value: item.name, label: item.name + ' (' + getRelativeDateDescription(item.date) + ')'}))}
                            onChange={(e) => handleSetSelectedBranchOrTagName(e?.value, 'TAG')}
                          />
                        </>
                      )}
                    </div>
                    <div className="mt-6">
                      <label htmlFor="upload-shared-resources">
                        <p className="flex items-center">
                          <input
                            checked={uploadSharedResources}
                            onChange={e => handleUploadSharedResources(e)}
                            id="upload-shared-resources"
                            type="checkbox"
                            className="w-5 h-5"
                          />
                          Upload shared resources
                        </p>
                      </label>
                    </div>
                    <div className="mt-6">
                      <label htmlFor="upload-content-types">
                        <p className="flex items-center">
                          <input
                            checked={uploadPublicationSpecificResources}
                            onChange={e => handleUploadPublicationSpecificResourcesChange(e)}
                            id="upload-content-types"
                            type="checkbox"
                            className="w-5 h-5"/>
                          Upload publication specific resources
                        </p>
                      </label>
                    </div>
                    {uploadPublicationSpecificResources && (
                      <div
                        className={`flex items-center mt-3 ${getUploadResourcesQuery.status === 'loading' ? '-z-10' : ''}`}>
                        <Select
                          isClearable
                          className="w-96 mr-4"
                          placeholder="Publications"
                          defaultValue={selectedPublications}
                          options={PUBLICATIONS.map(item => ({value: item, label: item}))}
                          onChange={(e) => handleSetSelectedPublications(e)}
                          isMulti
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-6">
                      <label htmlFor="debug">
                        <p className="flex items-center">
                          <input
                            checked={debug}
                            onChange={e => handleDebug(e)}
                            id="debug"
                            type="checkbox"
                            className="w-5 h-5"
                          />
                          Debug
                        </p>
                      </label>
                    </div>
                  <div className="mt-6 border-t border-t-sn-gray">
                    <Button
                      onClick={() => handleSubmit()}
                      size="l"
                      className="mt-6"
                      type="submit"
                      disabled={!!backgroundResultId || !selectedBranchOrTagName || (!uploadSharedResources && (!uploadPublicationSpecificResources || selectedPublications.length === 0))}
                    >
                      Upload
                    </Button>
                  </div>
                </>
              )}
              {branches.length === 0 && tags.length === 0 && getUploadResourcesQuery.status !== 'loading' && (
                <h3 className="text-red-600">No branches or tags returned from the API request</h3>
              )}
            </>
          )}
        </>
      )}
      {backgroundProgressPercentage === 100 && (
        <Button
          onClick={reset}
          size="l"
          className="mt-6"
          type="submit"
        >
          Go back
        </Button>
      )}
    </div>
  )
}

export default UploadResources
