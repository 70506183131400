// @ts-nocheck

import Select from "react-select";
import type React from "react";
import type {Article} from "./ChangeReviewerTable";
import {ALL_REVIEW_STATUSES} from "../ChangeReviewerUtils";


interface ChangeReviewerActionsBarProps {
  reviewers: any[];
  allReviewers: any[];
  showActions: boolean;
  selectedArticles: Article[];
  handleFilterByReviewers: (e: any) => any;
  handleFilterByStatus: (e: any) => any;
  handleSetReviewer: (userId: string) => void;
}

const ChangeReviewerActionsBar = (props: ChangeReviewerActionsBarProps) => {
  return (
    <div className="h-30 w-full bg-white shadow-md mb-4 p-4 flex">
      <div className="flex flex-col border-2 border-white">
        <div className="font-bold flex items-center">
          Filtrera artiklar
        </div>
        <div className="flex">
          <Select
            className="w-60 mr-4"
            placeholder="Status"
            options={ALL_REVIEW_STATUSES.map(item => ({value: item, label: item}))}
            onChange={e => props.handleFilterByStatus(e)}
            isMulti
          />
          <Select
            className="w-60"
            placeholder="Faktagranskare"
            options={props.reviewers.map(reviewer => ({value: reviewer.id, label: reviewer.name}))}
            onChange={e => props.handleFilterByReviewers(e)}
            isMulti
          />
        </div>
      </div>
      {props.showActions && (
        <div className={`flex flex-col border-white border-l-slate-100 border-2 pl-4 ml-4`}>
          <div className="flex">
            <div>
              <div className="font-bold flex items-center">
                Åtgärder
              </div>
              {!props.showActions && (
                <div className="italic" style={{ height: 42 }}>Välj minst en artikel</div>
              )}
              {props.showActions && (
                <div className="flex items-center">
                  <div className="flex items-center flex-wrap">
                    <div className="mr-4">Ändra faktagranskare till</div>
                    <Select
                      className="w-60"
                      placeholder="Välj"
                      options={props.allReviewers.map(reviewer => ({value: reviewer.id, label: reviewer.name}))}
                      onChange={e => props.handleSetReviewer(e.value)}
                    />
                    <div
                      className="ml-4">för {props.selectedArticles?.length} {props.selectedArticles?.length === 1 ? 'artikel' : 'artiklar'}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeReviewerActionsBar;
