import React from 'react';

type LoadingProps = {
  fullPageSpinner: boolean;
}

const Loading = (props: LoadingProps) => {
  return (
    <div className={props.fullPageSpinner ? 'flex items-center justify-center h-screen' : ''}>
      <p className="block w-20 h-20 border-8 border-sn-black/80 border-t-transparent rounded-full animate-spin">
        <span className="sr-only">Laddar</span>
      </p>
    </div>
  );
};

export default Loading;
