import React from "react";
import {getBgColorByStatus, getStatusByReviewDates, REVIEW_STATUS} from "../ChangeReviewerUtils";

interface ChangeReviewerRowProps {
  row: any;
}

const ChangeReviewerBodyRow = (props: ChangeReviewerRowProps) => {
  return (
    <tr className="hover:bg-slate-100 hover:cursor-pointer" {...props.row.getRowProps()}>
      {props.row.cells.map((cell: any, i: number) => {
        const article = cell?.row?.original;
        const reviewDate = cell?.row?.cells.find((item: any) => item?.column?.id === 'pendingReviewDate')?.value;
        const ongoingReviewStartDate = article?.ongoingReviewStartDate;
        const status = getStatusByReviewDates(reviewDate, ongoingReviewStartDate);
        const bgColorClass = getBgColorByStatus(status);

        if (cell?.column.id === 'status') {
          return (
            <td key={`${cell.column.id}-${i}`} onClick={() => props.row.toggleRowSelected()} {...cell.getCellProps()} className="px-4 py-1 text-left">
              {status === REVIEW_STATUS.NO_ACTION_REQUIRED && status}
              {status !== REVIEW_STATUS.NO_ACTION_REQUIRED && (
                <>
                  <div className={`w-fit text-white text-center px-4 py-1 rounded-full ${bgColorClass}`}>{status}</div>
                  {status === REVIEW_STATUS.ONGOING && (
                    <span className="text-slate-500 mt-40 whitespace-nowrap">Startades {ongoingReviewStartDate.slice(0, -6)}</span>
                  )}
                </>
              )}
            </td>
          )
        } else if (cell?.column.id === 'pendingReviewDate') {
          const myDate = cell?.value?.slice(0, -6);
          return (
            <td
              key={`${cell.column.id}-${i}`}
              onClick={() => props.row.toggleRowSelected()} {...cell.getCellProps()}
              className={`px-4 py-1`}>
              <div className="flex items-center justify-start">
                <div className={`rounded-full mr-2 ${bgColorClass}`} style={{ minWidth: 16, minHeight: 16 }}></div>
                <div className="whitespace-nowrap">{myDate}</div>
              </div>
            </td>
          )
        } else if (cell?.column.id === 'currentReviewer') {
          return (
            <td
              key={`${cell.column.id}-${i}`}
              onClick={() => props.row.toggleRowSelected()} {...cell.getCellProps()}
              className={`px-4 py-1`}>
              {cell?.value?.name}
            </td>
          )
        } else if (cell?.column.id === 'title') {
          return (
            <td
              key={`${cell.column.id}-${i}`}
              onClick={() => props.row.toggleRowSelected()} {...cell.getCellProps()}
              className={`px-4 py-1 max-w-xs`}>
              <a className="no-underline hover:underline" target="_blank" href={article.url}>
                {cell.render('Cell')}
              </a>
            </td>
          )
        } else if (cell?.column.id === 'latestArticleReviewReviewerName') {
          return (
            <td
              key={`${cell.column.id}-${i}`}
              onClick={() => props.row.toggleRowSelected()}
              {...cell.getCellProps()}
              className="px-4 py-1">
              {cell.value === '[Unknown]' ? 'Okänd' : cell.render('Cell')}
            </td>
          )
        } else if (cell?.column.id === 'url') {
          return (
            <td key={`${cell.column.id}-${i}`} {...cell.getCellProps()} className="px-4 py-1">
              {article?.cueUrl && (
                <>
                  <a className="no-underline hover:underline whitespace-nowrap" target="_blank" href={article?.cueUrl}>
                    Öppna i CUE
                  </a>
                  <br />
                </>
              )}
              <a className="no-underline hover:underline" target="_blank" href={`${article?.url}?backoffice=true&goaView=main&articleId=${article.id}`}>
                Granska
              </a>
            </td>
          )
        } else {
          return <td key={`${cell.column.id}-${i}`} onClick={() => props.row.toggleRowSelected()} {...cell.getCellProps()} className="px-4 py-1">{cell.render('Cell')}</td>
        }
      })}
    </tr>
  )
};

export default ChangeReviewerBodyRow;
