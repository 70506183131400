import { Route, Routes, useLocation } from 'react-router-dom'
import Layout from './Layout'
import routes from '../config/routes'
import useAuthenticatedUserQuery from '../hooks/api/useAuthenticatedUserQuery'
import Loading from "./Loading";

const App = () => {
  const { pathname } = useLocation()
  const authenticatedUserQuery = useAuthenticatedUserQuery({
    redirectIfNotLoggedIn: pathname !== '/logged-out',
  })

  if (authenticatedUserQuery.status === 'loading' || !authenticatedUserQuery.data) {
    return <Loading fullPageSpinner />
  }

  return (
    <Layout>
      <Routes>
        {routes.map(route => (
            <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Routes>
    </Layout>
  )
}

export default App
