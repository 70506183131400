import Icon from "./Icon";
import React from "react";

interface TablePaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
}

const TablePagination = (props: TablePaginationProps) => {
  
  return (
    <div className="pagination flex justify-center my-4">
      <button className={`flex p-2 rounded shadow ${props.canPreviousPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.gotoPage(0)} disabled={!props.canPreviousPage}>
        <Icon icon="chevronLeft" color={props.canPreviousPage ? 'black' : '#aaa'} />
        <Icon icon="chevronLeft" color={props.canPreviousPage ? 'black' : '#aaa'} />
      </button>{' '}
      <button className={`flex p-2 ml-2 rounded shadow ${props.canPreviousPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.previousPage()} disabled={!props.canPreviousPage}>
        <Icon icon="chevronLeft" color={props.canPreviousPage ? 'black' : '#aaa'} />
      </button>{' '}
      <button className={`flex p-2 ml-8 mr-4 rounded shadow ${props.canNextPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.nextPage()} disabled={!props.canNextPage}>
        <Icon icon="chevronRight" color={props.canNextPage ? 'black' : '#aaa'} />
      </button>{' '}
      <button className={`flex p-2 rounded shadow ${props.canNextPage ? 'bg-white hover:bg-slate-200' : 'bg-gray-300'}`} onClick={() => props.gotoPage(props.pageCount - 1)} disabled={!props.canNextPage}>
        <Icon icon="chevronRight" color={props.canNextPage ? 'black' : '#aaa'} />
        <Icon icon="chevronRight" color={props.canNextPage ? 'black' : '#aaa'} />
      </button>{' '}
    </div>
  )
}

export default TablePagination;
