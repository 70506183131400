import Container from './Container'
import Footer from './Footer'
import Header from './Header'
import { Toaster } from 'react-hot-toast'
import useStore from '../store'
import ColorHeader from "./ColorHeader";

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const isMainMenuVisible = useStore(state => state.mainMenu.isVisible)

  return (
    <>
      <Toaster />
      {isMainMenuVisible && <Header />}
      <ColorHeader />
      <Container as="main" className="p-8">
        {children}
      </Container>
    </>
  )
}

export default Layout
