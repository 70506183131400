import { useEffect } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import Select from 'react-select'
import type { FormReportPostPayload } from '../../hooks/api/types'
import useReportFormQuery from '../../hooks/api/useReportFormQuery'
import useReportFormMutation from '../../hooks/api/useReportFormMutation'
import Button from '../ui/Button'
import FileInput from '../ui/FileInput'
import Input from '../ui/Input'
import TextArea from '../ui/TextArea'

type Forfattare = {
  value: number
  label: string
}

type KopplingSakomrade = {
  value: number
  label: string
}

type KopplingFragorViArbetarMed = {
  value: number
  label: string
}

type Amnesord = {
  value: number
  label: string
}

type MyFile = {
  attachment?: FileList
  description: string
}

type FormData = {
  publication: 'ASAP' | 'SPECIFIC_TIME' | 'WAIT'
  message: string
  heading: string
  summary: string
  forfattare: Forfattare[]
  kopplingar: {
    sakomraden: KopplingSakomrade[]
    fragorViArbetarMed: KopplingFragorViArbetarMed[]
  }
  amnesord: Amnesord[]
  alsoInEnglish: boolean
  files: MyFile[]
}

const ReportForm = () => {
  const query = useReportFormQuery()
  const mutation = useReportFormMutation()

  // console.log(mutation)

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const { fields: fileFields, append, remove } = useFieldArray({ control, name: 'files' })

  const onSubmit = handleSubmit(data => {
    const payload: FormReportPostPayload & { files: Array<{ description: string; attachment?: FileList }> } = {
      report: {
        publishDateSelection: data.publication,
        messageToEditors: data.message,
        title: data.heading,
        summary: data.summary,
        authorIds: data.forfattare.map(f => f.value),
        focusAreaSectionIds: data.kopplingar.sakomraden.map(s => s.value),
        issueIds: data.kopplingar.fragorViArbetarMed.map(f => f.value),
        subjectIds: data.amnesord.map(a => a.value),
        publishEnglish: data.alsoInEnglish,
        externalAuthorsFreetext: '', // TODO: Add to form
        publishDateTime: '2022-03-29T10:33:18.824Z', // TODO: Add to form when SPECIFIC_TIME is selected
      },
      files: data.files,
    }

    mutation.mutate(payload)
  })

  return (
    <form onSubmit={onSubmit}>
      <p>Detta formulär används för att publicera rapporter på www.svensktnaringsliv.se.</p>
      <p>
        När formuläret skickats in laddas rapporten upp till sajten, men den publiceras inte direkt. Ett
        e-postmeddelande skickas till redaktionen@svensktnaringsliv.se som granskar och publicerar.
      </p>

      <p>
        Under rubriken Publicering nedan kan du skicka med ett meddelande till Redaktionen samt välja när rapporten ska
        publiceras.
      </p>

      <div className="mt-6">
        <h2>Publicering</h2>
        <h3>När vill du publicera publikationen?</h3>
        <label>
          <input {...register('publication')} value="ASAP" type="radio" name="publication" /> Snarast
        </label>
        <label>
          <input {...register('publication')} value="SPECIFIC_TIME" defaultChecked type="radio" name="publication" />{' '}
          Specifik tidpunkt (ange datum och klockslag nedan)
        </label>
        <label>
          <input {...register('publication')} value="WAIT" type="radio" name="publication" /> Avvakta med publicering,
          ladda endast upp
        </label>
      </div>

      <div className="mt-6">
        <label htmlFor="message">
          <h3>Meddelande till Redaktionen</h3>
        </label>
        <TextArea {...register('message')} id="message" defaultValue="value for message" />
      </div>

      <div className="mt-6">
        <h2>Om rapporten</h2>
        <label htmlFor="heading">
          <h3 className="required-field">Rubrik</h3>
        </label>
        <p>Om publikationen har en underrubrik, ta även med denna när du fyller i rubriken.</p>
        <Input {...register('heading')} id="heading" type="text" defaultValue="value for heading" />
      </div>

      <div className="mt-6">
        <label htmlFor="summary">
          <h3 className="required-field">Sammanfattning</h3>
        </label>
        <p>
          Sammanfattningen bör vara mellan 1000 och 1500 tecken. Sammanfattningens kvalité är direkt avgörande för hur
          bra sökmotorerna på Internet hittar publikationen.
        </p>
        <TextArea {...register('summary')} id="summary" defaultValue="value for summary" />
      </div>

      <div className="mt-6">
        <label htmlFor="authors">
          <h3 className="required-field">Författare</h3>
        </label>
        <p>
          Välj minst en intern eller extern författare. Interna författare är personer som är anställda på Svenskt
          Näringsliv.
        </p>

        <Controller
          name="forfattare"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              inputId="authors"
              placeholder="Välj en eller flera författare"
              isDisabled={query.status !== 'success'}
              isLoading={query.status === 'loading'}
              options={query.data?.authors?.map(author => ({ value: author.id, label: author.name }))}
              isMulti
            />
          )}
        />
      </div>

      <div className="mt-6">
        <label htmlFor="kopplingarSakomraden">
          <h3 className="required-field">Koppling till sakområde</h3>
        </label>
        <p>
          Genom att koppla publikationen till ett eller flera sakområden blir den synlig under det/de aktuella
          sakområdet/sakområderna.
        </p>
        <p>Det första sakområdet du väljer blir publikationens hemsektion.</p>
        <Controller
          name="kopplingar.sakomraden"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              inputId="kopplingarSakomraden"
              placeholder="Välj ett eller flera sakområden"
              isDisabled={query.status !== 'success'}
              isLoading={query.status === 'loading'}
              options={query.data?.focusAreas?.map(tag => ({ value: tag.id, label: tag.name }))}
              isMulti
            />
          )}
        />
      </div>

      <div className="mt-6">
        <label htmlFor="connectionQuestions">
          <h3 className="required-field">Koppling till frågor vi arbetar med</h3>
        </label>
        <p>Ange en eller flera frågor.</p>
        <Controller
          name="kopplingar.fragorViArbetarMed"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              inputId="connectionQuestions"
              placeholder="Välj en eller flera frågor"
              isDisabled={query.status !== 'success'}
              isLoading={query.status === 'loading'}
              options={query.data?.issueTags?.map(tag => ({ value: tag.id, label: tag.name }))}
              isMulti
            />
          )}
        />
      </div>

      <div className="mt-6">
        <label htmlFor="subjectWords">
          <h3 className="required-field">Ämnesord</h3>
        </label>
        <p>Ange ett eller flera ämnesord.</p>
        <Controller
          name="amnesord"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              inputId="subjectWords"
              placeholder="Välj en eller flera frågor"
              isDisabled={query.status !== 'success'}
              isLoading={query.status === 'loading'}
              options={query.data?.subjectTags?.map(tag => ({ value: tag.id, label: tag.name }))}
              isMulti
            />
          )}
        />
      </div>

      <div className="mt-6">
        <label htmlFor="alsoInEnglish">
          <h3 className="required-field">Skall även publiceras på engelska webben</h3>
          <p>
            <input {...register('alsoInEnglish')} id="alsoInEnglish" type="checkbox" />
            Alla publikationer publiceras på den svenska webbplatsen (även engelskspråkiga). Om du vill att rapporten
            även ska finnas med på den engelska delen av webbplatsen kryssar du i rutan.
          </p>
        </label>
      </div>

      <div className="mt-6">
        <h3 className="required-field">Filer</h3>
        <p>Bifoga publikationen samt eventuella bilagor som PDF.</p>
        <p>
          Klicka på knappen Bläddra för att bifoga filen och ange sedan publikationens rubrik i rutan "Filbeskrivning".
        </p>
      </div>

      {fileFields.length == 0 ? (
        <p className="mt-2 italic border border-sn-gray inline-block py-2 px-4 rounded-md text-sm">
          <span className="mr-2">👉</span> Klicka på "Lägg till fil" för att bifoga en eller flera filer
        </p>
      ) : (
        <div className="mt-6 grid grid-cols-2 gap-4">
          {fileFields.map((field, i) => {
            return (
              <div key={field.id}>
                <div className="flex items-center mb-2">
                  <label htmlFor={`file${i}Subject`}>
                    <h3 className="m-0">Fil {i + 1}</h3>
                  </label>
                  <button
                    type="button"
                    className="ml-2 text-sn-red w-6 h-6 flex items-center justify-center rounded-full border border-sn-gray hover:border-sn-black"
                    aria-label="Ta bort"
                    onClick={() => remove(i)}
                  >
                    &times;
                  </button>
                </div>
                <Input
                  className="mb-2"
                  {...register(`files.${i}.description`)}
                  id={`file${i}description`}
                  type="text"
                  placeholder="Ämne"
                  defaultValue={`value for file.${i}.description`}
                />
                <br />
                <FileInput {...register(`files.${i}.attachment`)} id={`file${i}`} />
              </div>
            )
          })}
        </div>
      )}
      <div>
        <Button className="mt-4" size="s" onClick={() => append({ description: '' })}>
          {fileFields.length === 0 ? 'Lägg till fil' : 'Lägg till en till fil'}
        </Button>
      </div>

      <div className="mt-6 border-t border-t-sn-gray">
        <Button className="mt-6" type="submit" disabled={query.status !== 'success' || mutation.isLoading}>
          Skicka till Redaktionen
        </Button>
      </div>
    </form>
  )
}

export default ReportForm
