import {differenceInDays, isAfter, isBefore, parseISO} from "date-fns";

export const REVIEW_STATUS = {
  ONGOING: 'Pågår',
  PAST_DUE: 'Försenad',
  SOON: 'Snart',
  NO_ACTION_REQUIRED: 'Åtgärd krävs ej'
}

export const ALL_REVIEW_STATUSES = [
  REVIEW_STATUS.ONGOING,
  REVIEW_STATUS.PAST_DUE,
  REVIEW_STATUS.SOON,
  REVIEW_STATUS.NO_ACTION_REQUIRED,
]

export const getStatusByReviewDates = (reviewDate: string, ongoingReviewStartDate: string): string => {
  const reviewDateHasPassed = isBefore(parseISO(reviewDate), new Date());
  const isInFuture = isAfter(parseISO(reviewDate), new Date());
  const isSoon = Math.abs(differenceInDays(new Date(), parseISO(reviewDate))) <= 14;

  let status;
  if (ongoingReviewStartDate) {
    status = REVIEW_STATUS.ONGOING;
  } else if (reviewDateHasPassed) {
    status = REVIEW_STATUS.PAST_DUE;
  } else if (isInFuture && isSoon) {
    status = REVIEW_STATUS.SOON;
  } else {
    status = REVIEW_STATUS.NO_ACTION_REQUIRED;
  }
  return status;
}

export const getBgColorByStatus = (status: string): string => {
  let bgColorClass;
  switch (status) {
    case REVIEW_STATUS.ONGOING: bgColorClass = 'bg-sn-blue'; break;
    case REVIEW_STATUS.PAST_DUE: bgColorClass = 'bg-sn-red'; break;
    case REVIEW_STATUS.SOON: bgColorClass = 'bg-cta-primary'; break;
    // case REVIEW_STATUS.NO_ACTION_REQUIRED: bgColorClass = 'bg-green-600'; break;
    default: bgColorClass = 'text-gray-400';
  }

  return bgColorClass;
}
