import {useEffect, useState} from "react";

const ColorHeader = () => {
  const [envColor, setEnvColor] = useState('');

  useEffect(() => {
    if (/test-bo/.test(location.origin)) {
      setEnvColor('bg-sn-snorgron');
    } else if (/localhost|stage-bo/.test(location.origin)) {
      setEnvColor('bg-sn-pissgul');
    } else {
      setEnvColor('');
    }
  }, []);

  if (!envColor) return null;
  return <header className={`h-14 ${envColor}`}></header>
}

export default ColorHeader
