import ChangeReviewerForm from "../components/forms/ChangeReviewerForm/ChangeReviewerForm";


const ChangeReviewer = () => {
  return (
    <div>
      <ChangeReviewerForm />
    </div>
  )
};

export default ChangeReviewer;
