import TablePagination from "./TablePagination";
import Select from "react-select";
import Icon from "./Icon";


interface TableControlsProps {
  itemSize: number;
  pageIndex: number;
  pageOptions: any;
  pageCount: number;
  pageSize: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (num: number | 'alla') => void;
  handleRefreshClicked: () => void;
}

const TableControls = (props: TableControlsProps) => {
  return (
    <div className="flex justify-center sm:justify-between items-center flex-wrap mb-4 sm:mb-0">
      <div className="flex items-center mx-2">
        <div>
          <strong>{props.itemSize} träffar </strong>
          (Sida {props.pageIndex + 1} av {props.pageOptions.length})
        </div>
        <button
          className="flex p-2 ml-4 mr-4 rounded shadow bg-white hover:bg-slate-200 items-center"
          onClick={props.handleRefreshClicked}>
          <Icon icon="refresh" color={'#444'} />
          <span className={'ml-2'}>Uppdatera</span>
        </button>
      </div>
      <TablePagination
        //@ts-ignore
        className="mx-2"
        canPreviousPage={props.canPreviousPage}
        canNextPage={props.canNextPage}
        pageCount={props.pageCount}
        gotoPage={props.gotoPage}
        previousPage={props.previousPage}
        nextPage={props.nextPage}
      />
      <Select
        className="mx-2"
        placeholder={`Visa ${props.pageSize}`}
        options={[10, 20, 30, 40, 50, props.itemSize].map(item => ({ value: item, label: `Visa ${item === props.itemSize ? 'alla' : item}` }))}
        onChange={e => props.setPageSize(Number(e?.value))}
      />
    </div>
  )
};

export default TableControls;
