import ReportForm from '../components/forms/ReportForm'

const Report = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <h1>Ladda upp rapport till www.svensktnaringsliv.se</h1>
      <ReportForm />
    </div>
  )
}

export default Report
