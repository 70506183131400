import type {IconBaseProps} from 'react-icons'
import {
  FaAngleDown,
  FaAngleUp,
  FaChevronLeft,
  FaChevronRight,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaUser,
  FaSearch,
  FaFilter,
} from 'react-icons/fa'

import { HiRefresh } from 'react-icons/hi';

type Props = {
  icon: 'arrowDown' | 'arrowUp' | 'chevronRight' | 'chevronLeft' | 'user' | 'sort' | 'sortUp' | 'sortDown' | 'search' | 'filter' | 'refresh'
} & IconBaseProps

const ICONS = {
  arrowDown: FaAngleDown,
  arrowUp: FaAngleUp,
  chevronRight: FaChevronRight,
  chevronLeft: FaChevronLeft,
  user: FaUser,
  sort: FaSort,
  sortUp: FaSortUp,
  sortDown: FaSortDown,
  search: FaSearch,
  filter: FaFilter,
  refresh: HiRefresh,
}

const Icon = ({icon, ...restProps}: Props) => {
  const IconComponent = ICONS[icon] ?? null
  if (!IconComponent) {
    throw new Error(`Icon "${icon}" not found`)
  }

  return <IconComponent {...restProps} />
}

export default Icon
