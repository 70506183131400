import { useQuery } from 'react-query'
import { API_URLS } from '../../config/constants'
import axiosClient from '../../config/axiosClient'
import type { UploadResourcesGetResponse } from "./types";

export const getChangeUploadResourcesForm = async () => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const { data } = await axiosClient.get<UploadResourcesGetResponse>(API_URLS.FORMS.UPLOAD_RESOURCES.GET)

  return data
}

const useChangeReviewerFormQuery = () => {
  return useQuery<UploadResourcesGetResponse>('changeUploadResourcesForm', getChangeUploadResourcesForm)
}

export default useChangeReviewerFormQuery;
