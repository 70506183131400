import type { FormReportGetResponse } from './types'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import axiosClient from '../../config/axiosClient'
import { API_URLS } from '../../config/constants'
import SNToast, {renderToast} from "../../components/SNToast";
import {getFriendlyErrorMessage} from "../../utils/errorCodeMessages";

export const getReportForm = async () => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const { data } = await axiosClient.get<FormReportGetResponse>(API_URLS.FORMS.REPORT.GET)

  return data
}

const useReportFormQuery = () => {
  return useQuery<FormReportGetResponse | undefined>('reportForm', getReportForm, {
    onError: (err: any) => {
      renderToast({ type: 'error', title: getFriendlyErrorMessage(err?.response?.data?.errorCode), err })
    },
  })
}

export default useReportFormQuery
