import Icon from "../../../ui/Icon";

export interface SortByColumn {
  id: string | null;
  directionDesc: boolean | null;
}

interface ChangeReviewerHeaderProps {
  headerGroup: any;
  handleSortClicked: (sortVal: SortByColumn) => void;
}

const ChangeReviewerHeaderRow = (props: ChangeReviewerHeaderProps) => {

  return (
    <tr {...props.headerGroup.getHeaderGroupProps()}>
      {props.headerGroup.headers.map((column: any) => {
        const toggleProps = column.getHeaderProps(column.getSortByToggleProps());

        const onSortClicked = (e: any) => {
          if (column.id !== 'selection') {
            toggleProps.onClick(e);
            let sortByDirectionDesc = false;
            if (column.isSorted) {
              sortByDirectionDesc = !column.isSortedDesc;
            }
            const sortBy: SortByColumn = column.isSorted && column.isSortedDesc ? { id: null, directionDesc: null } : { id: column.id, directionDesc: sortByDirectionDesc };
            props.handleSortClicked(sortBy);
          }
        };

        return (
          // Add the sorting props to control sorting. For this example
          // we can add them into the header props
          <th
            {...toggleProps}
            className="p-4 bg-slate-200 text-left"
            onClick={onSortClicked}
          >
            <div className="flex items-center">
              <span className={column.isSorted ? 'text-sn-link' : ''}>{column.render('Header')}</span>
              {/* Add a sort direction indicator */}
              <span>
              {column.isSorted
                ? column.isSortedDesc
                  ? <Icon icon="sortDown"/>
                  : <Icon icon="sortUp"/>
                : (column?.id !== 'selection' && column?.id !== 'url')
                  ? <Icon icon="sort"/>
                  : ''
              }
            </span>
            </div>
          </th>
        );
      })}
    </tr>
  )
};

export default ChangeReviewerHeaderRow;
