import clsx from 'clsx'
import type { InputHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import type { UseFormRegister } from 'react-hook-form'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
}

const FileInput = forwardRef<HTMLInputElement, Props & ReturnType<UseFormRegister<any>>>(
  ({ className = '', ...restProps }, ref) => {
    return (
      <input
        type="file"
        className={clsx(
          'block w-full border border-sn-gray cursor-pointer text-sn-blue focus:outline-none focus:border-transparent text-sm rounded-r-sm',
          {
            [className]: !!className,
          }
        )}
        {...restProps}
        ref={ref}
      />
    )
  }
)

export default FileInput
