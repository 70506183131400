import Link from './Link'
import routes from '../config/routes'

const MainMenu = () => {
  return (
    <nav className="py-4">
      <ul className="flex gap-4">
        {
          routes
            .filter(({ showInMainMenu }) => showInMainMenu)
            .map(({ path, menuText }) => (
              <li key={path}>
                <Link className="text-white pb-1" activeClass="border-b-2" to={path}>
                  {menuText}
                </Link>
              </li>
            ))}
        {(
          <li className="ml-auto">
            <a className="text-white" href="/logout">
              Logga ut
            </a>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default MainMenu
