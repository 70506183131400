import Container from './Container'
import MainMenu from './MainMenu'

const Header = () => {
  return (
    <header className="bg-sn-blue">
      <Container>
        <MainMenu />
      </Container>
    </header>
  )
}

export default Header
