import UserProfileForm from '../components/forms/UserProfileForm'

const UserProfile = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <UserProfileForm />
    </div>
  )
}

export default UserProfile
