// @ts-nocheck
import TableControls from "../../../ui/TableControls";
import ChangeReviewerHeaderRow from "./ChangeReviewerHeaderRow";
import ChangeReviewerBodyRow from "./ChangeReviewerBodyRow";
import React, {useEffect} from "react";
import {useTable, usePagination, useSortBy, useRowSelect} from 'react-table';

const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input className="w-5 h-5" type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const NoDataTableRow = () => {
  return (
    <tr>
      <td className="p-4 text-center" colSpan="10">Hittade inga artiklar</td>
    </tr>
  )
};

export interface Article {
  id: number;
  contenType: string;
  contentTypeLabel: string;
  created: string;
  currentReviewer: any;
  pendingReviewDate: string;
  title: string;
  url: string;
}

const ChangeReviewerTable = ({columns, data, handleRowSelected, handleRefreshClicked, handleSortClicked, initialSortById, initialSortByIdDirection}) => {
  // Use the state and functions returned from useTable to build the UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{
          id: initialSortById,
          desc: initialSortByIdDirection,
        }]
      }
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllPageRowsSelectedProps method
          // to render a checkbox
          Header: ({getToggleAllPageRowsSelectedProps}) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({row}) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    handleRowSelected(selectedFlatRows.map(item => item?.original?.id));
  }, [selectedFlatRows]);

  // Render the UI
  return (
    <>
      <TableControls
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        pageSize={pageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        itemSize={data.length}
        handleRefreshClicked={handleRefreshClicked}
      />
      <div className="table-wrapper-style">
        <table {...getTableProps()} className="table-style">
          <thead>
          {headerGroups.map((headerGroup, i) => <ChangeReviewerHeaderRow key={i} headerGroup={headerGroup} handleSortClicked={handleSortClicked}/>)}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page?.length === 0 && <NoDataTableRow/>}
          {page?.length > 0 && page.map((row, j) => {
            prepareRow(row);
            return <ChangeReviewerBodyRow key={j} row={row}/>
          })}
          </tbody>
        </table>
      </div>
      <TableControls
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        pageSize={pageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        itemSize={data.length}
        handleRefreshClicked={handleRefreshClicked}
      />
    </>
  )
}

export default ChangeReviewerTable;
