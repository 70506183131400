
interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  children: JSX.Element;
}

const Alert = (props: AlertProps) => {
  let colorClass;
  switch (props.type) {
    case 'success': colorClass = 'bg-green-500'; break;
    case 'error': colorClass = 'bg-sn-red'; break;
    case 'warning': colorClass = 'bg-cta-primary'; break;
    case 'info': colorClass = 'bg-sn-blue'; break;
    default: colorClass = 'bg-sn-blue'; break;
  }
  return (
    <div className={`p-4 my-4 ${colorClass} text-white`}>
      {props.children}
    </div>
  )
};

export default Alert;
