import type { FormChangeReviewerGetResponse } from './types'
import { useQuery } from 'react-query'
import { API_URLS } from '../../config/constants'
import axiosClient from '../../config/axiosClient'

export const getChangeReviewerForm = async () => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const { data } = await axiosClient.get<FormChangeReviewerGetResponse>(API_URLS.FORMS.CHANGE_REVIEWER.GET_ARTICLES)

  return data
}

const useChangeReviewerFormQuery = () => {
  return useQuery<FormChangeReviewerGetResponse | undefined>('changeReviewerForm', getChangeReviewerForm)
}

export default useChangeReviewerFormQuery;
