

interface ProgressBarProps {
  percentage: number;
  className?: string;
  failure?: boolean;
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div className='h-8 w-full bg-gray-300'>
      <div
        style={{ width: `${props.percentage}%`}}
        className={`h-full flex justify-start items-center ${props.percentage < 100 ? 'animate-pulse': ''} ${
          props.failure ? 'bg-red-600' : props.percentage < 100 ? 'bg-blue-500' : 'bg-green-600'}`}>
        <span className="ml-4 text-white">{props.percentage}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
