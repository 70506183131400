import { useQuery } from 'react-query'
import type { SettingsResponse } from '../../../api/types'
import type { AuthenticatedUser } from '../api/types'
import axios from 'axios'
import { FUNCTION_API_URLS } from '../../config/constants'
import useAuthenticatedUserQuery from '../api/useAuthenticatedUserQuery'

export const getSettings = async (authenticatedUser: AuthenticatedUser) => {
  if (!FUNCTION_API_URLS.BASE) {
    throw Error('Could not resolve FUNCTION_API_BASE_URL environment url')
  }

  const response = await axios.post<SettingsResponse>(FUNCTION_API_URLS.SETTINGS, 
    {
      authenticatedUser
    }
  )
  
  return response.data.settings
}

const useSettingsQuery = () => {
  const authenticatedUserQuery = useAuthenticatedUserQuery()
  if (authenticatedUserQuery.data) {
    return useQuery('settings', () => getSettings(authenticatedUserQuery.data))
  }
  return {
    data: undefined
  };
}

export default useSettingsQuery
