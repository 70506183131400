import clsx from 'clsx'
import type { InputHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import type { UseFormRegister } from 'react-hook-form'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
}

const Input = forwardRef<HTMLInputElement, Props & ReturnType<UseFormRegister<any>>>(
  ({ type = 'text', className = '', ...restProps}, ref) => {
    return (
      <input
        type={type}
        className={clsx(`border border-sn-gray rounded ${type !== 'checkbox' ? 'w-full' : ''} py-2 px-3`, {
          [className]: !!className,
        })}
        {...restProps}
        ref={ref}
      />
    )
  }
)

export default Input
