const BACKOFFICE_API_BASE_URL = process.env.BACKOFFICE_API_BASE_URL
const FUNCTION_API_BASE_URL = process.env.FUNCTION_API_BASE_URL
const PAGE_HREF = encodeURIComponent(window.location.href)

export const API_URLS = {
  // Example force error:
  // BASE: BACKOFFICE_API_BASE_URL + '?forceErrorCode=GENERAL_ERROR',
  BASE: BACKOFFICE_API_BASE_URL,
  AUTH: `${BACKOFFICE_API_BASE_URL}/login?redirect=${PAGE_HREF}`,
  FORMS: {
    REPORT: {
      POST: `${BACKOFFICE_API_BASE_URL}/v1/createReport`,
      GET: `${BACKOFFICE_API_BASE_URL}/v1/createReport`,
    },
    COWORKER: {
      POST: `${BACKOFFICE_API_BASE_URL}/v1/myCoworkerPage`,
      GET: `${BACKOFFICE_API_BASE_URL}/v1/myCoworkerPage`,
    },
    CHANGE_REVIEWER: {
      GET_ARTICLES: `${BACKOFFICE_API_BASE_URL}/v1/goa/listArticles`,
      GET_ALL_REVIEWERS: `${BACKOFFICE_API_BASE_URL}/v1/listCoworkers`,
      POST: `${BACKOFFICE_API_BASE_URL}/v1/goa/changeReviewer`,
    },
    HAWK: {
      POST: `${BACKOFFICE_API_BASE_URL}/v2/hawk/purgeCache`,
    },
    UPLOAD_RESOURCES: {
      GET: `${BACKOFFICE_API_BASE_URL}/v1/cue/uploadResources`,
      POST: `${BACKOFFICE_API_BASE_URL}/v1/cue/uploadResources`,
    }
  },
}

export const FUNCTION_API_URLS = {
  BASE: FUNCTION_API_BASE_URL,
  SETTINGS: `${FUNCTION_API_BASE_URL}/settings`,
}
