import useAuthenticatedUserQuery from '../hooks/api/useAuthenticatedUserQuery'
import useSettingsQuery from '../hooks/api/useSettingsQuery'
import { API_URLS, FUNCTION_API_URLS } from '../config/constants'

function showObject(input : any): any {
  if (!input) {
    return (
      <li key='not_available'><i><b>...</b></i></li>
    )
  }

  if (Array.isArray(input)) {
    return input.map(showObject)
  }

  if (typeof input === 'object') {
    return (
      <li><pre>{JSON.stringify(input, undefined, 2)}</pre></li>
    )
  }

  return (
    <li>{String(input)}</li>
  )
}

const DebugData = () => {
  const authenticatedUserQuery = useAuthenticatedUserQuery()
  const settingsQuery = useSettingsQuery()
  return (
    <div>
      <h2>User</h2>
      <ul>
        {showObject(authenticatedUserQuery.data)}
      </ul>
      <hr/>
      <h2>API_URLS</h2>
      <ul>
        {showObject(API_URLS)}
      </ul>
      <hr/>
      <h2>FUNCTION_API_URLS</h2>
      <ul>
        {showObject(FUNCTION_API_URLS)}
      </ul>
      <hr/>
      <h2>Settings</h2>
      <ul>
        {showObject(settingsQuery.data)}
      </ul>
      <hr/>
      <h2>vite meta env:</h2>
      <ul>
        {showObject(import.meta.env)}
      </ul>
    </div>
  )
}

export default DebugData
