import { useMutation, useQueryClient } from 'react-query'
import type { FormReportPostPayload, FormReportPostResponse } from './types'
import axiosClient from '../../config/axiosClient'
import { API_URLS } from '../../config/constants'
import {renderToast} from "../../components/SNToast";
import {getFriendlyErrorMessage} from "../../utils/errorCodeMessages";

export const postReportForm = async (
  formData: FormReportPostPayload & { files: Array<{ description: string; attachment?: FileList }> }
) => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const formDataPayload = new FormData()
  formDataPayload.append('body', JSON.stringify({ report: formData.report }))
  // TODO: Use file description as key instead of index + 1
  // @ts-ignore
  formData.files.forEach((file, i) => formDataPayload.append(`file-${i + 1}`, file.attachment[0]))

  const { data } = await axiosClient.post<FormReportPostResponse>(API_URLS.FORMS.REPORT.GET, formDataPayload, {
    headers: { 'content-type': 'multipart/form-data' },
  })

  return data
}

const useReportFormMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(postReportForm, {
    onError: (err: any) => {
      renderToast({ type: 'error', title: getFriendlyErrorMessage(err?.response?.data?.errorCode), err })
    },
    onSuccess: () => {
      renderToast({ type: 'success', title: 'Hurra!' })
      queryClient.invalidateQueries('coworkerForm')
    },
  })
}

export default useReportFormMutation
