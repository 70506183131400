import type {SNErrorCode} from '../hooks/api/types';

export const getFriendlyErrorMessage = (err: SNErrorCode) => {
  const defaultMessage = 'Anledning okänd.';
  switch (err) {
    case 'GENERAL_ERROR':
      return defaultMessage;
    case 'NO_AUTHENTICATED_USER_FOUND':
      return 'Du är inte inloggad';
    case 'INVALID_API_VERSION':
      return 'Felaktig version av API:et';
    case 'INVALID_API_PATH':
      return 'Felaktig sökväg till API:et';
    case 'API_ENDPOINT_NOT_FOUND':
      return 'API:et kunde inte hittas';
    case 'HTTP_METHOD_NOT_ALLOWED':
      return 'HTTP-metoden är inte tillåten.';
    case 'MISSING_REQUEST_BODY':
      return 'Anropsdata saknas.';
    case 'INVALID_REQUEST_BODY':
      return 'Anropsdatat var felaktigt.';
    case 'MISSING_REQUIRED_DATA_IN_REQUEST_BODY':
      return 'Ett eller flera obligatoriska fält saknades.';
    case 'MISSING_REQUIRED_FILES':
      return 'En eller flera obligatoriska filer saknades.';
    case 'INVALID_FILE_TYPE':
      return 'Felaktig filtyp.';
    case 'CONCURRENT_MODIFICATION':
      return 'Flera samtidiga modifieringar har ägt rum';
    case 'RESOURCE_LOCKED':
      return 'Resursen du försöker nå är låst.';
    case 'FORBIDDEN':
      return 'Du har inte tillåtelse att utföra denna åtgärd.';
    case 'SEARCH_FAILED':
      return 'Sökningen misslyckades.';
    case 'TOO_MANY_REQUESTS':
      return 'För många anrop på kort tid.';
    case 'NO_PROFILE_ARTICLE_FOUND':
      return 'Ditt konto är inte kopplat till en publik medarbetarsida på svensktnaringsliv.se. Kontakta HR-avdelningen på hr@svensktnaringsliv.se så hjälper de dig att skapa en sådan.';
    case 'PROFILE_ARTICLE_NOT_PUBLISHED':
      return 'Profilartikeln hittades men är inte publicerad.';
    case 'PROFILE_ARTICLE_EXIST_FOR_COWORKER_WITH_SAME_EMAIL':
      return 'En profilartikel existerar redan för en anställd med samma epost-adress.';
    case 'INVALID_FORMAT_FOR_SN_REGISTRATION_NUMBER':
      return 'Felaktigt format på internt diarienummer.';
    default:
      return defaultMessage
  }
}
