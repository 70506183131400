import { useQuery } from 'react-query'
import type { BaseEndpointResponse } from './types'
import axiosClient from '../../config/axiosClient'
import { API_URLS } from '../../config/constants'

export const getAuthenticatedUser = async (options: Options) => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }

  const response = await axiosClient.get<BaseEndpointResponse>(API_URLS.BASE)

  const authenticatedUser = response.data.apiRequest.authenticatedUser

  // Strict boolean check to avoid a false positive
  if (options.redirectIfNotLoggedIn && !authenticatedUser) {
    window.location.href = API_URLS.AUTH
  }

  return authenticatedUser
}

type Options = {
  redirectIfNotLoggedIn: boolean
}

const defaultOptions = {
  redirectIfNotLoggedIn: true,
}

const useAuthenticatedUserQuery = (inputOptions: Options = defaultOptions) => {
  const options = {
    ...defaultOptions,
    ...inputOptions,
  }
  return useQuery('authenticatedUser', () => getAuthenticatedUser(options))
}

export default useAuthenticatedUserQuery
