import {useMutation} from "react-query";
import {API_URLS} from "../../config/constants";
import axiosClient from "../../config/axiosClient";
import type {FormUploadResourcesPostPayload, UploadResourcesPostResponse} from "./types";


export const postUploadResources = async (formData: FormUploadResourcesPostPayload) => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url');
  }
  const { data } = await axiosClient.post<UploadResourcesPostResponse>(API_URLS.FORMS.UPLOAD_RESOURCES.POST, formData);
  return data;
}

const usePostUploadResourcesMutation = () => {
  return useMutation(postUploadResources);
}

export default usePostUploadResourcesMutation
