import { useMutation, useQueryClient } from 'react-query'
import type { FormCoworkerPostPayload, FormCoworkerPostResponse } from './types'
import axiosClient from '../../config/axiosClient'
import { API_URLS } from '../../config/constants'
import {renderToast} from "../../components/SNToast";
import {getFriendlyErrorMessage} from "../../utils/errorCodeMessages";

export const postCoWorkerForm = async (formData: FormCoworkerPostPayload) => {
  if (!API_URLS.BASE) {
    throw Error('Could not resolve BACKOFFICE_API_BASE_URL environment url')
  }
  const { data } = await axiosClient.post<FormCoworkerPostResponse>(API_URLS.FORMS.COWORKER.POST, formData)
  return data
}

const useCoWorkerFormMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(postCoWorkerForm, {
    onError: (err: any) => {
      renderToast({ type: 'error', title: getFriendlyErrorMessage(err?.response?.data?.errorCode), err })
    },
    onSuccess: () => {
      renderToast({ type: 'success', title: 'Din profilartikel har uppdaterats' })
      queryClient.invalidateQueries('coworkerForm')
    },
  })
}

export default useCoWorkerFormMutation
